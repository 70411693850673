const doubleSpaceRegExp = new RegExp(/\s\s+/, 'g')

function deSpace (str) {
	return str.trim().replace(doubleSpaceRegExp, ' ')
}

export default {
	computed: {
		isNameConfirmed: function () {
			return deSpace(this.company.company) === deSpace(this.confirmationName)
		}
	}
}
