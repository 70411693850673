<!-- eslint-disable vue/no-v-html -->
<template>
	<w-flex shrink>
		<w-btn color="warning" flat icon="refresh" mini small @click="dialog = true">{{ $t('customer.administration.restore_customer') }}</w-btn>
		<w-dialog v-model="dialog" max-width="500" :title="$t('customer.administration.restore_customer')" warning>
			<w-flex v-t="'customer.administration.restore_customer_dialog.first'" my-2 />
			<w-flex v-t="'customer.administration.restore_customer_dialog.second'" my-2 />
			<w-flex my-2 v-html="$t('customer.administration.restore_customer_dialog.third', { name: company.company })" />
			<w-flex my-2>
				<v-text-field v-model="confirmationName" color="warning" @paste.prevent />
			</w-flex>
			<template v-slot:actions>
				<w-btn color="warning" class="ml-auto mr-0" :disabled="!isNameConfirmed" flat @click="restoreCustomer()">
					{{ $t('customer.administration.restore_customer') }}
				</w-btn>
			</template>
		</w-dialog>
	</w-flex>
</template>

<script>
import CustomersManagerModuleGuard from '@/mixins/ModulesGuards/CustomersManager/CustomersManagerModuleGuard'
import SanitizedConfirmation from "@/mixins/CustomersManager/CustomerDetails/Administration/SanitizedConfirmation";

export default {
	name: 'CompanyRestorer',
	mixins: [CustomersManagerModuleGuard, SanitizedConfirmation],
	props: {
		value: {
			required: true,
			type: Object,
			validator: propValue => {
				return propValue.deleted_at && propValue.id
			}
		}
	},
	data: function () {
		return {
			confirmationName: '',
			dialog: false
		}
	},
	computed: {
		company: function () {
			return this.value
		},
	},
	watch: {
		dialog: {
			handler: 'onDialogStateChanged'
		}
	},
	methods: {
		/**
		 * @param {boolean} isOpen
		 */
		onDialogStateChanged: function (isOpen) {
			return new Promise(resolve => {
				if (!isOpen) {
					this.confirmationName = ''
				}

				resolve()
			})
		},
		restoreCustomer: function () {
			this.service.restoreCustomer(this.company).then(() => {
				this.dialog = false
			})
		}
	}
}
</script>
